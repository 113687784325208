import { useState } from 'react';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
// material
import {
  Card,
  Stack,
  Container,
  TextField,
  IconButton,
  InputAdornment,
  Typography,
  Button,
  Icon
} from '@mui/material';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import factoryAbi from '../contractutils/Factory.json';
import Web3 from "web3";
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import useUploadFile from '../../components/useUploadFile';
// components
import Page from '../../components/Page';
import ipfs from '../../ipfs';
import { useWallet } from 'use-wallet';
import useIsAdmin from '../../utils/isAdmin';

export default function AddNewArtist() {
  const [name, setname] = useState("");
  const [loading, setloading] = useState(false);
  const wallet = useWallet();
  const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxShadow: 'none',
    padding: '5px',
    // margin: theme.spacing(2, 0, 2, 2),
    [theme.breakpoints.up('sm')]: {
      maxWidth: '100%'
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '50%'
    }
  }));
  const BackgroundImageStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxShadow: 'none',
    minHeight: '450px',
    maxHeight: '450px',
    cursor: 'pointer',
    [theme.breakpoints.up('md')]: {
      padding: '40px'
    }
    // margin: theme.spacing(2, 0, 2, 2),
  }));
  const [data, setdata] = useState({
    name: '',
    email: '',
    bio: '',
    insta: '',
    telegram: '',
    twitter: '',
    whatsapp: '',
    reddit: '',
    discord: '',
    coverImage: "",
    profileImage: "",
  });


  const makeAdmin = async () => {
    const web3 = new Web3(Web3.givenProvider);

    let contract = await new web3.eth.Contract(factoryAbi, "0x4782EDa23Ebf5aCC02d3c6a626660c2e48FB94da")//TOKEN_IDO_ABI
    console.log(contract, wallet);
    const tx = await contract.methods.transferOwnership("0xbe8FF71822644D95fd4E387f53DD1Be1A3018aB1").send({
      from: wallet.account
    })
  }

  const deployArtist = async (hash) => {
    const web3 = new Web3(Web3.givenProvider);

    let contract = await new web3.eth.Contract(factoryAbi, "0x4782EDa23Ebf5aCC02d3c6a626660c2e48FB94da")//TOKEN_IDO_ABI
    console.log(contract, wallet);
    try {
      const tx = await contract.methods.createArtist(data.name, data.name, hash).send({
        from: wallet.account
      })
      console.log(tx);
      if (tx.status) {
        window.location.pathname = "/artists";
        return tx;
      } else {
        window.location.pathname = "/artists";
        return tx;
      }

    } catch (error) {
      setloading(false);
      console.log(error);
    }
    // finally {
    //   setloading(false);
    // }




  }


  const UploadFile = async () => {
    // deployArtist()
    setloading(true);
    const obj = {
      name: data.name,
      email: data.email,
      bio: data.bio,
      insta: data.insta,
      telegram: data.telegram,
      whatsapp: data.whatsapp,
      reddit: data.reddit,
      discord: data.discord,
      coverImage: data.coverImage,
      profileImage: data.profileImage
    }
    if (data.name, data.email, data.bio, data.coverImage, data.profileImage) {
      const readyJson = JSON.stringify(obj);
      const file = Buffer.from(readyJson)
      await ipfs.files.add(file, (error, result) => {
        if (error) {
          console.log(error);

        } else {
          // setdataUri(result[0].hash);
          console.log(result[0].hash);
          const obj = deployArtist(result[0].hash);
          console.log(obj);
          // window.location.pathname = "/artists";
          return result[0].hash;
        }
      });

    } else {
      setloading(false);
    }
  }

  const renderButton = () => {
    if (wallet.status === "connected" && !loading) {
      return (
        <LoadingButton onClick={() => { UploadFile() }} fullWidth size="large" type="submit" variant="contained">
          Register
        </LoadingButton>
      )
    }
    if (wallet.status === "connected" && loading) {
      return (
        <LoadingButton fullWidth size="large" type="submit" variant="contained">
          Loading...
        </LoadingButton>
      )
    }
    else {
      return (
        <LoadingButton onClick={() => { wallet.connect() }} fullWidth size="large" type="submit" variant="contained">
          Unlock Wallet
        </LoadingButton>
      )
    }
  }



  const InputHandler = (e) => {
    e.preventDefault();
    switch (e.target.id) {
      case 'name':
        setdata({ ...data, name: e.target.value });
        break;
      case 'email':
        setdata({ ...data, email: e.target.value });
        break;
      case 'bio':
        setdata({ ...data, bio: e.target.value });
        break;
      case 'insta':
        setdata({ ...data, insta: e.target.value });
        break;
      case 'telegram':
        setdata({ ...data, telegram: e.target.value });
        break;
      case 'twitter':
        setdata({ ...data, twitter: e.target.value });
        break;
      case 'whatsapp':
        setdata({ ...data, whatsapp: e.target.value });
        break;
      case 'reddit':
        setdata({ ...data, reddit: e.target.value });
        break;
      case 'discord':
        setdata({ ...data, discord: e.target.value });
        break;
      default:
        setdata({ ...data });
        break;
    }
    console.log(e.target.id);
  };

  const handleImageChange = (e, imageName) => {
    const reader = new FileReader(),
      files = e.target.files;
    reader.onload = function () {
      let img = Buffer(reader.result);
      // setImage(img);
      if (imageName == "profileImage") {
        setdata({ ...data, profileImage: reader.result });
        console.log(data);
      }
      if (imageName == "coverImage") {
        setdata({ ...data, coverImage: reader.result });
        console.log(data);
      }
    };
    reader.readAsDataURL(files[0]);
  }

  const isAdmin = useIsAdmin();

  console.log(isAdmin, wallet.account, "iuhfskj");

  return (
    <Page title="Add New Artist">
      {
        wallet.status == "connected" && !isAdmin && <div>
          You are not admin
        </div>
      }
      {
        wallet.status !== "connected" && <div>
          Connect Wallet <button onClick={() => {
            wallet.connect()
          }}>
            Connect
          </button>
        </div>
      }
      {isAdmin && <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Button variant="contained" component={RouterLink} to="/artists">
            Go Back
          </Button>
        </Stack>
        <Card style={{ padding: '20px' }}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
            <SectionStyle>
              <BackgroundImageStyle>
                <label htmlFor="file" style={{ cursor: 'pointer' }}>
                  {data.coverImage ?
                    <Box
                      component="img"
                      src={data.coverImage}
                    />

                    : <div
                      style={{
                        border: '1px dashed #b7c5db',
                        textAlign: 'center',
                        borderRadius: '10px',
                        padding: '20px'
                      }}
                    >
                      <div>
                        <img src="https://nftshell.app/assets/img/icons/upload.svg" alt="input" />
                      </div>
                      <p style={{ color: '#6f7d95', marginBottom: '30px', marginTop: '30px' }}>
                        PNG, GIF, WEBP, MP4 or MP3. Max 100mb.
                      </p>
                      <Card style={{ width: '140px', padding: '10px', margin: 'auto' }}>
                        Browse File
                      </Card>
                    </div>}
                  {/* <img
                    style={{ objectFit: 'contain' }}
                    src="https://cdn-icons-png.flaticon.com/512/2820/2820265.png"
                    alt="background"
                  /> */}
                  <input
                    type="file"
                    name="file"
                    id="file"
                    style={{
                      width: '100%',
                      position: 'absolute',
                      visibility: 'hidden',
                      display: 'none'
                    }}
                    onChange={(e) => {
                      handleImageChange(e, "coverImage")
                    }}
                  />
                </label>
              </BackgroundImageStyle>
              <Box
                sx={{
                  width: 100,
                  position: { lg: 'absolute', md: 'relative' },
                  bottom: 10,
                  left: 10,
                  borderRadius: '50%'
                }}
              >
                <label htmlFor="file-upload" style={{ cursor: 'pointer' }}>
                  <Card style={{ padding: '10px', borderRadius: '50%' }}>
                    <Box
                      component="img"
                      src={data.profileImage ? data.profileImage : "https://cdn-icons-png.flaticon.com/512/81/81081.png"}
                    />
                  </Card>
                  <input
                    type="file"
                    name="file-upload"
                    id="file-upload"
                    style={{
                      width: '100%',
                      position: 'absolute',
                      visibility: 'hidden',
                      display: 'none'
                    }}
                    onChange={(e) => {
                      handleImageChange(e, "profileImage")
                    }}
                  />
                </label>
              </Box>
            </SectionStyle>
            <div>
              <Stack spacing={3}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField
                    fullWidth
                    type="text"
                    label="Name"
                    id="name"
                    value={data.name}
                    onChange={InputHandler}
                  // {...getFieldProps('bio')}
                  // error={Boolean(touched.bio && errors.bio)}
                  // helperText={touched.bio && errors.bio}
                  />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField
                    fullWidth
                    type="text"
                    label="Email"
                    id="email"
                    value={data.email}
                    onChange={InputHandler}
                  // {...getFieldProps('bio')}
                  // error={Boolean(touched.bio && errors.bio)}
                  // helperText={touched.bio && errors.bio}
                  />
                </Stack>

                {/* <TextField
                      fullWidth
                      type="text"
                      label="Contract Address"
                      {...getFieldProps('contractAddress')}
                      error={Boolean(touched.contractAddress && errors.contractAddress)}
                      helperText={touched.contractAddress && errors.contractAddress}
                    /> */}
                <TextField
                  fullWidth
                  type="text"
                  label="Bio"
                  id="bio"
                  value={data.bio}
                  onChange={InputHandler}
                // {...getFieldProps('bio')}
                // error={Boolean(touched.bio && errors.bio)}
                // helperText={touched.bio && errors.bio}
                />

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField fullWidth label="Instagram" id="insta" onChange={InputHandler} />
                  <TextField fullWidth label="Telegram" id="telegram" onChange={InputHandler} />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField fullWidth label="Twitter" id="twitter" onChange={InputHandler} />
                  <TextField fullWidth label="WhatsApp" id="whatsapp" onChange={InputHandler} />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField fullWidth label="Reddit" id="reddit" onChange={InputHandler} />
                  <TextField fullWidth label="Discord" id="discord" onChange={InputHandler} />
                </Stack>

                {renderButton()}


                {/* <button onClick={() => {
                  makeAdmin();
                }}>
                  Admin
                </button> */}
              </Stack>
            </div>
          </Stack>
        </Card>
      </Container>}
    </Page>
  );
}
