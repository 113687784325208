import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
// material
import {
    Card,
    Stack,
    Container,
    TextField,
    IconButton,
    InputAdornment,
    Typography,
    Button,
    Icon
} from '@mui/material';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';

import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import PRODUCTS from '../../_mocks_/products';
import { ProductList } from '../../components/_dashboard/products';

// components
import Page from '../../components/Page';
import { useSelector } from 'react-redux';
import ipfs from '../../ipfs';

export default function ArtistDetailsPage(props) {
    const [showPassword, setShowPassword] = useState(false);
    // const artist = useSelector((state) => state.singleArtist)
    const [productArray, setproductArray] = useState("");
    const [artist, setartist] = useState("");

    async function getData(items) {
        const data = items.map(async (e) => {
            let obj = {};
            if (e.uri) {
                const result = await ipfs.get(`/ipfs/${e.uri}`);
                // return result[0].content;
                console.log(result[0].content.toString());

                let data = JSON.parse(result[0].content.toString());
                console.log(data);
                obj.id = e.id;
                obj.isSold = e.isSold;
                obj.owner = e.owner;
                obj.price = e.price;
                obj.tokenId = e.tokenId;
                obj.image = data.image;
                obj.name = data.name;
                obj.description = data.description;
                obj.quantity = data.quantity;
                return obj;
            }
        });
        const fetchedData = await Promise.all(data);
        console.log(fetchedData);
        setproductArray(fetchedData);

        return fetchedData
    }

    const StyledImage = styled(Card)(({ theme }) => ({
        width: '25px',
        height: '25px',
        cursor: 'pointer',
        padding: '3px'
    }));

    useEffect(() => {

        console.log(artist, "artist");
        getData(props.nfts);
        setartist(props.data)
        // setproductArray(props.nfts);
        return () => {

        }
    }, [artist])


    const SectionStyle = styled(Card)(({ theme }) => ({
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        boxShadow: 'none',
        padding: theme.spacing(2, 2, 2, 2),
        [theme.breakpoints.up('sm')]: {
            maxWidth: '100%'
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: '50%'
        }
    }));
    const BackgroundImageStyle = styled(Card)(({ theme }) => ({
        width: '100%',
        display: 'none',
        flexDirection: 'column',
        justifyContent: 'center',
        boxShadow: 'none',
        maxHeight: '300px',
        [theme.breakpoints.up('lg')]: {
            display: 'flex'
        },
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        }
        // margin: theme.spacing(2, 0, 2, 2),
    }));

    const ArtistDescription = styled(Card)(({ theme }) => ({
        boxShadow: 'none',
        padding: '3px',
        margin: 'auto',
        [theme.breakpoints.up('lg')]: {
            margin: '0'
        },
        [theme.breakpoints.up('md')]: {
            margin: '0'
        },
        [theme.breakpoints.up('sm')]: {
            margin: '0'
        }
    }));

    const RegisterSchema = Yup.object().shape({
        firstName: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('First name required'),
        lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name required'),
        email: Yup.string().email('Email must be a valid email address').required('Email is required'),
        password: Yup.string().required('Password is required')
    });
    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            password: ''
        },
        validationSchema: RegisterSchema,
        onSubmit: () => {
            console.log('hii');
            //   navigate('/dashboard', { replace: true });
        }
    });
    const { errors, touched, handleReset, handleSubmit, isSubmitting, getFieldProps } = formik;

    return (
        <Page title="Add New Artist">
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    {/* <Typography variant="h4" gutterBottom>
            User
          </Typography> */}
                    <Button variant="contained" component={RouterLink} to="/artists">
                        Go Back
                    </Button>
                    <Button variant="contained" component={RouterLink} to={`/artist/${props.data.id}/add-nft`}>
                        Add Nft
                    </Button>
                </Stack>
                <Card style={{ marginBottom: '30px' }}>
                    <Button style={{ position: "absolute", top: "5", right: "0", zIndex: 9999, margin: "2px" }} variant="contained" component={RouterLink} to={`/artist/edit/${artist.id}`}>
                        Edit
                    </Button>
                    <BackgroundImageStyle>

                        <img
                            style={{ objectFit: 'contain', width: "100%", margin: "auto" }}
                            src={artist?.coverImage}
                            alt="background"
                        />
                    </BackgroundImageStyle>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
                        <SectionStyle>
                            <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
                                <ArtistDescription>
                                    <Box
                                        component="img"
                                        src={artist?.profileImage}
                                        sx={{ width: 100, height: 80, borderRadius: "50%" }}
                                    />
                                </ArtistDescription>
                                <ArtistDescription>
                                    <div style={{ marginTop: '10px', margin: 'auto' }}>
                                        <h2 style={{ margin: 'auto' }}>{artist?.name}</h2>
                                        <p>{artist?.bio}</p>
                                        <div style={{ display: 'flex' }}>
                                            <Stack spacing={2} direction={{ xs: 'row', sm: 'row' }}>
                                                <StyledImage>
                                                    <img
                                                        alt="instagram"
                                                        src="https://cdn-icons-png.flaticon.com/512/2111/2111491.png"
                                                    />
                                                </StyledImage>
                                                <StyledImage>
                                                    <img
                                                        alt="twitter"
                                                        src="https://cdn-icons-png.flaticon.com/512/2111/2111819.png"
                                                    />
                                                </StyledImage>
                                                <StyledImage>
                                                    <img
                                                        alt="telegram"
                                                        src="https://cdn-icons-png.flaticon.com/512/2111/2111812.png"
                                                    />
                                                </StyledImage>
                                                <StyledImage>
                                                    <img
                                                        alt="whatsapp"
                                                        src="https://cdn-icons-png.flaticon.com/512/1384/1384023.png"
                                                    />
                                                </StyledImage>
                                            </Stack>
                                        </div>
                                    </div>
                                </ArtistDescription>
                            </Stack>
                        </SectionStyle>
                    </Stack>
                </Card>
                {productArray && <ProductList products={productArray} />}
                {!productArray && <div style={{ width: "100%", height: '100%' }}>
                    <img style={{ margin: "auto", width: "100%", height: '100%' }} src={"/images/loading.gif"} />
                </div>}
            </Container>
        </Page>
    );
}
