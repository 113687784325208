import { ACCOUNT_UPDATE, CONTRACT_LOADED, RATE, SINGLE_ARTIST } from "../constants/action-types";


export function setSingleArtist(payload) {
  return {
    type: SINGLE_ARTIST, payload
  }
}


export function accountUpdate(payload) {
  console.log(payload, "payload")
  return { type: ACCOUNT_UPDATE, payload }
};

export function contractLoaded(payload) {
  return { type: CONTRACT_LOADED, payload }
}

export function ethtodollar(payload) {
  return { type: RATE, payload }
}

