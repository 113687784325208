import React, { useEffect, useState } from 'react'
import { useWallet } from 'use-wallet'
import Web3 from 'web3';
// import multicall from '../pages/utils/idofactory.json'
import factoryAbi from '../pages/contractutils/Factory.json';

function useIsAdmin() {

    const wallet = useWallet();

    let status = false

    const [ownerAddress, setownerAddress] = useState("");

    useEffect(async () => {


        const web3 = new Web3(Web3.givenProvider);
        let contract = await new web3.eth.Contract(factoryAbi, "0x4782EDa23Ebf5aCC02d3c6a626660c2e48FB94da")//TOKEN_IDO_ABI /// 0xF466164E54E5Cc5BC5539787caF57d0014b6E400

        // const multi = new web3.eth.Contract(multicall, "0x2F84EaB66347Bc19fdDb805436152dDD0f04f835");
        console.log(contract, "loggss");

        const owner = await contract.methods.owner().call();
        // await multi.methods.owner().call();
        const propsedOwner = ""
        // await multi.methods.proposedOwner().call();

        // const 

        console.log(propsedOwner, "proposed owner", owner);
        await setownerAddress(owner);
        console.log(wallet);
        if (wallet.status === "connected" && wallet.account == owner || wallet.account == propsedOwner) {
            status = true;
        } else {
            status = false;
        }

    }, [wallet.account, wallet.status])

    return wallet.status === "connected" && wallet.account == ownerAddress;

}

export default useIsAdmin