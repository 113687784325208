import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
// material
import {
    Card,
    Stack,
    Container,
    TextField,
    IconButton,
    InputAdornment,
    Typography,
    Button,
    Icon
} from '@mui/material';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';

import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import PRODUCTS from '../../_mocks_/products';
import { ProductList } from '../../components/_dashboard/products';

// components
import Page from '../../components/Page';
import { useSelector } from 'react-redux';
import ipfs from '../../ipfs';
import ArtistDetailsPage from './ArtistDetails';
import { gql, useQuery } from '@apollo/client';
import useIsAdmin from '../../utils/isAdmin';
import { useWallet } from 'use-wallet';

const GET_SINGLE_NFT = gql`
   query NFT($id:String, $owner:String){
    artist(id:$id){
        id
        createdAt
        uri
        totalSupply
   }
   nfts(where:{artist:$owner}){
        id
        isSold
        tokenId
        owner
        price
        uri
        artist{
        id
        }
        transfers{
        hash
        id
        }
   }
   }

`


export default function ArtistDetails() {

    const { data } = useQuery(GET_SINGLE_NFT, {
        variables: {
            id: `${window.location.pathname.split("/")[2]}`,
            owner: `${window.location.pathname.split("/")[2]}`
        }
    });
    const wallet = useWallet();

    const [artsitData, setartsitData] = useState("");
    console.log(data, "data");


    async function getData(e) {
        // const data = items.map(async (e) => {
        console.log("user Data", e);
        let obj = {};
        if (e.uri) {
            const result = await ipfs.get(`/ipfs/${e.uri}`);
            // return result[0].content;
            console.log(result[0].content.toString());

            let data = JSON.parse(result[0].content.toString());
            console.log(data);
            obj.id = e.id;
            obj.totalSupply = e.totalSupply;
            obj.saleEnabled = e.saleEnabled;
            obj.name = data.name;
            obj.email = data.email;
            obj.bio = data.bio;
            obj.insta = data.insta;
            obj.telegram = data.telegram;
            obj.whatsapp = data.whatsapp;
            obj.reddit = data.reddit;
            obj.discord = data.discord;
            obj.coverImage = data.coverImage;
            obj.profileImage = data.profileImage;

            setartsitData(obj)
            return obj;
        }
        // const fetchedData = await Promise.all(data);
        // console.log(fetchedData);
        // setuserArray(fetchedData)
        // return fetchedData
    }


    useEffect(() => {

        if (data) {
            getData(data.artist);
        }
        return () => {

        }
    }, [data])

    const isAdmin = useIsAdmin();


    return (
        <div>
            {
                wallet.status == "connected" && !isAdmin && <div>
                    You are not admin
                </div>
            }
            {
                wallet.status !== "connected" && <div>
                    Connect Wallet <button onClick={() => {
                        wallet.connect()
                    }}>
                        Connect
                    </button>
                </div>
            }
            {isAdmin && <div>{artsitData && <ArtistDetailsPage data={artsitData} nfts={data.nfts} />}
                {!artsitData && <div style={{ width: "100%", height: '100%' }}>
                    <img style={{ margin: "auto", width: "100%", height: '100%' }} src={"/images/loading.gif"} />
                </div>}
            </div>}
        </div>
    );
}
