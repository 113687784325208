import React from 'react'
import UserList from './userlist'

import { gql, useQuery } from '@apollo/client';


const GETALL_ARTIST = gql`
  query{
    artists{
      id
      uri
      totalSupply
      saleEnabled
      createdAt
    }
    nfts{
    id
    isSold
    tokenId
    owner
    price
    uri
    artist{
      id
    }
    transfers{
      hash
      id
    }
  }
}
`



function User() {
  const { data } = useQuery(GETALL_ARTIST);

  console.log(data, "datat");

  return (
    <div>
      {data && <UserList data={data.artists} />}
      {!data && <div style={{ width: "100%", height: '100%' }}>
        <img style={{ margin: "auto", width: "100%", height: '100%' }} src={"/images/loading.gif"} />
      </div>}
    </div>
  )
}

export default User