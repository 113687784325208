import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Box, Card, Link, Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
import { fCurrency } from '../../../utils/formatNumber';
//
import Label from '../../Label';
import ColorPreview from '../../ColorPreview';

// ----------------------------------------------------------------------

const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object
};

export default function ShopProductCard({ product }) {
  const { name, cover, image, description, price, colors, status, priceSale } = product;

  return (
    <Card>
      {product.isSold && <div style={{
        position: "absolute",  backgroundColor: "#000000",
        color: "#FFFFFF",
        textAlign: "center",
        padding: "0.5rem 2rem",
        fontWeight: 600,
        position: "absolute",
        right: 0,
        top: 0
      }}>
        Sold Out
      </div>}
      <Box sx={{ pt: '100%', position: 'relative' }}>
        {/* {status && (
          <Label
            variant="filled"
            color={(status === 'sale' && 'error') || 'info'}
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              position: 'absolute',
              textTransform: 'uppercase'
            }}
          >
            {status}
          </Label>
        )} */}
        <ProductImgStyle alt={name} src={image} />
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Link to="#" color="inherit" underline="hover" component={RouterLink}>
          <Typography variant="subtitle2" noWrap>
            {name || "test1 name"}
            <span>
              <span style={{ fontSize: "20px", color: "#000", verticalAlign: "middle", display: "flex" }}>
                <img src="/images/bnb.png" style={{ width: "20px", height: "20px", margin: "auto", display: "inline", marginRight: "5px", marginLeft: "0px" }} />

                {price ? price / 10 ** 18 : '$49.99'}
              </span>
            </span>
          </Typography>

        </Link>

        {/* <p style={{ marginTop: '0px', color: '#919EAB', fontSize: '14px' }}>
          {description || "Test description"}
        </p> */}

        {/* <Stack direction="row" alignItems="center" justifyContent="space-between">
         
          <Typography variant="subtitle1">
            <Typography
              component="span"
              variant="body1"
              sx={{
                color: 'text.disabled',
                textDecoration: 'line-through'
              }}
            >
              {priceSale && fCurrency(priceSale)}
            </Typography>
            &nbsp;
            
          </Typography>
        </Stack> */}
      </Stack>
    </Card >
  );
}
