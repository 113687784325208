import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import Products from './pages/Products';
import Blog from './pages/Blog';
import NotFound from './pages/Page404';

// import AddNewNft from './pages/AddNewNft';
import EditArtist from './pages/EditArtist';
import AddNewNft from './pages/Addnewnft';
import AddNewArtist from './pages/Artistaddnew';
import ArtistDetails from './pages/Artistdetails';
import User from './pages/user';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/artists" replace /> },
        { path: 'app', element: <DashboardApp /> },
        { path: 'artists', element: <User /> },
        { path: 'artist/add-new', element: <AddNewArtist /> },
        { path: 'artist/:contractaddress', element: <ArtistDetails /> },
        { path: 'artist/edit/:contractaddress', element: <EditArtist /> },
        { path: 'artist/:contractaddress/add-nft', element: <AddNewNft /> },
        { path: 'artist/:contractaddress/nft', element: <User /> },
        // { path: 'artist/:contractaddress/nft/edit', element: <User /> }

        // { path: 'products', element: <Products /> },
        // { path: 'blog', element: <Blog /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/dashboard" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
