import { ACCOUNT_UPDATE, CONTRACT_LOADED, RATE, SEAPORT, SINGLE_ARTIST } from "../constants/action-types";



const initialState = {
    account: null,
    blockchainClient:null,
    singleArtist:null
  };
  
  function rootReducer(state = initialState, action) {
    console.log(action);
    switch(action.type){
        case ACCOUNT_UPDATE:
            return Object.assign({}, state, {
                account: action.payload
              });
        case CONTRACT_LOADED:
              return Object.assign({}, state, {
                contract: action.payload
              });
        case RATE:
          return Object.assign({}, state, {
            rate: action.payload
          });
        case SINGLE_ARTIST:
          return Object.assign({}, state, {
            singleArtist: action.payload
          });
    }
    



    return state;
  };
  
  export default rootReducer;