import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
    Card,
    Table,
    Stack,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination
} from '@mui/material';
import { styled } from '@mui/material/styles';
import products from '../../_mocks_/products';

import useUploadFile from '../../components/useUploadFile';

// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../components/_dashboard/user';
//
import USERLIST from '../../_mocks_/user';
import ipfs from '../../ipfs';
import { setSingleArtist } from '../../redux/actions';
import { useDispatch } from 'react-redux';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'bio', label: 'Bio', alignRight: false },
    // { id: 'genre', label: 'Genre', alignRight: false },
    { id: 'contractaddress', label: 'Contract Address', alignRight: false },
    { id: 'socialmedias', label: 'Social Medias', alignRight: false },
    { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function UserList(props) {





    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);


    const dispatch = useDispatch();

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = USERLIST.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const StyledImage = styled(Card)(({ theme }) => ({
        width: '25px',
        height: '25px',
        cursor: 'pointer',
        padding: '3px'
    }));

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

    const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

    const isUserNotFound = filteredUsers.length === 0;

    const [userArray, setuserArray] = useState("");

    async function getData(items) {
        const data = items.map(async (e) => {
            let obj = {};
            if (e.uri) {
                const result = await ipfs.get(`/ipfs/${e.uri}`);
                // return result[0].content;
                console.log(result[0].content.toString());

                let data = JSON.parse(result[0].content.toString());
                console.log(data);
                obj.id = e.id;
                obj.totalSupply = e.totalSupply;
                obj.saleEnabled = e.saleEnabled;
                obj.name = data.name;
                obj.email = data.email;
                obj.bio = data.bio;
                obj.insta = data.insta;
                obj.telegram = data.telegram;
                obj.whatsapp = data.whatsapp;
                obj.reddit = data.reddit;
                obj.discord = data.discord;
                obj.coverImage = data.coverImage;
                obj.profileImage = data.profileImage;
                return obj;
            }
        });
        const fetchedData = await Promise.all(data);
        console.log(fetchedData);
        setuserArray(fetchedData)
        return fetchedData
    }

    useEffect(() => {
        console.log(props);
        // getData(props.data);

        if (props) {
            // setuserArray(props.data)
            getData(props.data);
        }
        // setuserArray(data)

        return () => {

        }
    }, [props])


    return (
        <Page title="Artists">
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Artists
                    </Typography>
                    <Button
                        variant="contained"
                        component={RouterLink}
                        to="/artist/add-new"
                        startIcon={<Icon icon={plusFill} />}
                    >
                        New Artist
                    </Button>
                </Stack>

                <Card>
                    <UserListToolbar
                        numSelected={selected.length}
                        filterName={filterName}
                        onFilterName={handleFilterByName}
                    />

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={USERLIST.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {userArray && userArray.length > 0 && userArray

                                        .map((row) => {
                                            const { id, name, profileImage, insta, coverImage, telegram, twitter, bio, whatsapp } = row;
                                            const isItemSelected = selected.indexOf(name) !== -1;
                                            // console.log(JSON.parse(row.toString()), (JSON.parse(row.toString()).profileImage));
                                            return (
                                                <TableRow
                                                    hover
                                                    // key={id}
                                                    tabIndex={-1}
                                                    role="checkbox"
                                                    selected={isItemSelected}
                                                    aria-checked={isItemSelected}
                                                    style={{ textDecoration: 'none' }}
                                                >
                                                    <TableCell padding="checkbox">
                                                        {/* <Checkbox
                                                        checked={isItemSelected}
                                                        onChange={(event) => handleClick(event, name)}
                                                        /> */}
                                                    </TableCell>
                                                    <TableCell component={RouterLink}
                                                        onClick={() => {
                                                            dispatch(setSingleArtist(row))
                                                        }}
                                                        to={`/artist/${row.id}`} scope="row" padding="none">
                                                        <Stack direction="row" alignItems="center" spacing={2}>
                                                            <Avatar alt={""} src={profileImage} />
                                                            <Typography variant="subtitle2" noWrap>
                                                                {name}
                                                            </Typography>
                                                        </Stack>
                                                    </TableCell>
                                                    <TableCell component={RouterLink}
                                                        to={`/artist/${row.id}`} align="left">{bio}</TableCell>
                                                    {/* <TableCell align="left">{role}</TableCell> */}
                                                    <TableCell component={RouterLink}
                                                        to={`/artist/${row.id}`} align="left">{row.id}</TableCell>
                                                    <TableCell align="left">
                                                        <div style={{ display: 'flex' }}>
                                                            <Stack spacing={2} direction={{ xs: 'row', sm: 'row' }}>
                                                                <StyledImage>
                                                                    <img
                                                                        onClick={() => { window.open(insta, "__blank__") }}
                                                                        alt="instagram"
                                                                        src="https://cdn-icons-png.flaticon.com/512/2111/2111491.png"
                                                                    />
                                                                </StyledImage>
                                                                <StyledImage>
                                                                    <img
                                                                        onClick={() => { window.open(twitter, "__blank__") }}

                                                                        alt="twitter"
                                                                        src="https://cdn-icons-png.flaticon.com/512/2111/2111819.png"
                                                                    />
                                                                </StyledImage>
                                                                <StyledImage>
                                                                    <img
                                                                        onClick={() => { window.open(telegram, "__blank__") }}

                                                                        alt="telegram"
                                                                        src="https://cdn-icons-png.flaticon.com/512/2111/2111812.png"
                                                                    />
                                                                </StyledImage>
                                                                <StyledImage>
                                                                    <img
                                                                        onClick={() => { window.open(whatsapp, "__blank__") }}

                                                                        alt="whatsapp"
                                                                        src="https://cdn-icons-png.flaticon.com/512/1384/1384023.png"
                                                                    />
                                                                </StyledImage>
                                                            </Stack>
                                                        </div>
                                                    </TableCell>

                                                    {/* <TableCell align="right">
                            <UserMoreMenu />
                          </TableCell> */}
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                    {!userArray && <div style={{ width: "100%", height: '100%' }}>
                                        <img style={{ margin: "auto", width: "100%", height: '100%' }} src={"/images/loading.gif"} />
                                    </div>}
                                </TableBody>
                                {isUserNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <SearchNotFound searchQuery={filterName} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={USERLIST.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>
        </Page>
    );
}
