import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
// material
import {
    Card,
    Stack,
    Container,
    TextField,
    IconButton,
    InputAdornment,
    Typography,
    Button,
    Icon,
    TextareaAutosize
} from '@mui/material';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import artistAbi from '../contractutils/Artist.json';

import Web3 from "web3";

import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// components
import Page from '../../components/Page';
import ipfs from '../../ipfs';
import { useWallet } from 'use-wallet';

export default function AddNewNft() {
    const [showPassword, setShowPassword] = useState(false);
    const [contractAddress, setcontractAddress] = useState("");
    const [contractObj, setcontractObj] = useState("");
    const [artistUri, setartistUri] = useState("");
    const [userData, setuserData] = useState("");
    const [loading, setloading] = useState(false);
    const wallet = useWallet();

    useEffect(() => {

        const contractAd = window.location.pathname.split("/")[2];
        console.log(contractAd);
        setcontractAddress(contractAd);

        return () => {

        }
    }, [])


    useEffect(async () => {
        if (contractAddress) {
            const web3 = new Web3(Web3.givenProvider);

            let contract = await new web3.eth.Contract(artistAbi, contractAddress)//TOKEN_IDO_ABI
            console.log(contract, wallet);
            setcontractObj(contract);
        }

        return () => {

        }
    }, [contractAddress])



    const [data, setdata] = useState({
        name: '',
        quantity: "",
        price: "",
        description: "",
        image: "",
        sku: "",
        tags: "",
        category: "",
    });

    const handleImageChange = (e, imageName) => {
        const reader = new FileReader(),
            files = e.target.files;
        reader.onload = function () {
            let img = Buffer(reader.result);
            // setImage(img);
            if (imageName == "image") {
                setdata({ ...data, image: reader.result });
                console.log(data);
            }

        };
        reader.readAsDataURL(files[0]);
    }

    const insertZero = (value, length) => {
        const intialValue = "000000000000000000";
        // const length = value.length;
        const finalstring = intialValue.slice(length);
        console.log(finalstring, value.concat(finalstring), "final");
        return value.concat(finalstring);
    }

    const handleAmount = (amount) => {
        if (amount.indexOf(".") != -1) {
            console.log("in if");
            const int = amount.split(".");
            console.log("int", int);
            const value = insertZero(int[1], int[1].length);
            const tens = int[0].concat("000000000000000000");
            const total = parseInt(tens) + parseInt(value);
            console.log(total);
            return total.toString();
            // return int.concat("0000000000000000000");
        } else {
            const value = amount.concat("000000000000000000");
            console.log(value);
            return value;
        }
    }

    const InputHandler = (e) => {
        e.preventDefault();
        switch (e.target.id) {
            case 'name':
                setdata({ ...data, name: e.target.value });
                break;
            case 'quantity':
                setdata({ ...data, quantity: e.target.value });
                break;
            case 'price':
                const priceData  = handleAmount(e.target.value);
                setdata({ ...data, price: priceData });
                break;
            case 'description':
                setdata({ ...data, description: e.target.value });
                break;
            case 'sku':
                setdata({ ...data, sku: e.target.value });
                break;
            case 'tags':
                setdata({ ...data, tags: e.target.value });
                break;
            case 'category':
                setdata({ ...data, category: e.target.value });
                break;
            default:
                setdata({ ...data });
                break;
        }
        console.log(e.target.id);
    };


    const entryInContract = async (hash) => {
        const tx = await contractObj.methods.createNft(data.quantity, hash, data.price).send({ from: wallet.account });
        console.log(tx, "transaction");
        if (tx.status) {
            window.location.pathname = "/artists";
        } else {
            window.location.pathname = "/artists";
        }
    }


    const renderButton = () => {
        if (wallet.status === "connected" && !loading) {
            return (
                <LoadingButton onClick={() => { UploadFile() }} fullWidth size="large" type="submit" variant="contained">
                    Add
                </LoadingButton>
            )
        }
        if (wallet.status === "connected" && loading) {
            return (
                <LoadingButton fullWidth size="large" type="submit" variant="contained">
                    Loading...
                </LoadingButton>
            )
        }
        else {
            return (
                <LoadingButton onClick={() => { wallet.connect() }} fullWidth size="large" type="submit" variant="contained">
                    Unlock Wallet
                </LoadingButton>
            )
        }
    }



    const UploadFile = async () => {
        const obj = {
            name: data.name,
            quantity: data.quantity,
            image: data.image,
            price: data.price,
            description: data.description,
            sku: data.sku,
            category: data.category,
            tags: data.category
        }
        if (data.name, data.quantity, data.price, data.image, data.description) {
            const readyJson = JSON.stringify(obj);
            const file = Buffer.from(readyJson)
            await ipfs.files.add(file, (error, result) => {
                if (error) {
                    console.log(error);

                } else {
                    // setdataUri(result[0].hash);
                    console.log(result[0].hash);
                    entryInContract(result[0].hash)
                    return result[0].hash;
                }
            });

        }
    }

    const SectionStyle = styled(Card)(({ theme }) => ({
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        boxShadow: 'none',
        // margin: theme.spacing(2, 0, 2, 2),
        [theme.breakpoints.up('sm')]: {
            maxWidth: '100%'
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: '50%'
        }
    }));
    const BackgroundImageStyle = styled(Card)(({ theme }) => ({
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        boxShadow: 'none',
        minHeight: '200px',
        maxHeight: '450px',
        [theme.breakpoints.up('md')]: {
            padding: '40px'
        }
        // margin: theme.spacing(2, 0, 2, 2),
    }));

    // const RegisterSchema = Yup.object().shape({
    //   title: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Title required'),
    //   shortDescription: Yup.string()
    //     .min(20, 'Too Short!')
    //     .max(150, 'Too Long!')
    //     .required('Short Description required')
    // });
    // const formik = useFormik({
    //   initialValues: {
    //     title: '',
    //     shortDescription: '',
    //     quantity: '',
    //     price: ''
    //   },
    //   validationSchema: RegisterSchema,
    //   onSubmit: () => {
    //     console.log('hii');
    //     //   navigate('/dashboard', { replace: true });
    //   }
    // });
    // const { errors, touched, handleReset, handleSubmit, isSubmitting, getFieldProps } = formik;

    return (
        <Page title="Add New Artist">
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    {/* <Typography variant="h4" gutterBottom>
            User
          </Typography> */}
                    <Button variant="contained" component={RouterLink} to="/artists">
                        Go Back
                    </Button>
                </Stack>
                <Card style={{ padding: '20px' }}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
                        <SectionStyle>
                            <BackgroundImageStyle>
                                <label htmlFor="fileNFT" style={{ cursor: 'pointer' }}>
                                    {data.image ? <img src={data.image} /> : <div
                                        style={{
                                            border: '1px dashed #b7c5db',
                                            textAlign: 'center',
                                            borderRadius: '10px',
                                            padding: '20px'
                                        }}
                                    >
                                        <div>
                                            <img src="https://nftshell.app/assets/img/icons/upload.svg" alt="input" />
                                        </div>
                                        <p style={{ color: '#6f7d95', marginBottom: '30px', marginTop: '30px' }}>
                                            PNG, GIF, WEBP, MP4 or MP3. Max 100mb.
                                        </p>
                                        <Card style={{ width: '140px', padding: '10px', margin: 'auto' }}>
                                            Browse File
                                        </Card>
                                    </div>}
                                    {/* <img
                    src="https://cdn-icons-png.flaticon.com/512/2820/2820265.png"
                    alt="background"
                  /> */}
                                    <input
                                        type="file"
                                        name="fileNFT"
                                        id="fileNFT"
                                        style={{
                                            width: '100%',
                                            position: 'absolute',
                                            visibility: 'hidden',
                                            display: 'none'
                                        }}
                                        onChange={(e) => { handleImageChange(e, "image") }}
                                    />
                                </label>
                            </BackgroundImageStyle>
                        </SectionStyle>
                        <div style={{ padding: "15px", width: "100%" }}>
                            {/* <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate> */}
                            <Stack spacing={3} direction={{ xs: 'column', sm: 'column' }}>
                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                    <TextField
                                        fullWidth
                                        label="Title"
                                        id="name"
                                        onChange={InputHandler}
                                    // {...getFieldProps('title')}
                                    // error={Boolean(touched.title && errors.title)}
                                    // helperText={touched.title && errors.title}
                                    />
                                </Stack>
                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                    <TextField
                                        onChange={InputHandler}
                                        id="quantity"
                                        fullWidth label="Quantity" />
                                </Stack>
                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                    <TextField
                                        onChange={InputHandler}
                                        id="price"
                                        fullWidth label="Price" />
                                </Stack>
                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                    <TextField
                                        onChange={InputHandler}
                                        id="sku"
                                        fullWidth label="SKU" />
                                </Stack>
                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                    <TextField
                                        onChange={InputHandler}
                                        id="tags"
                                        fullWidth label="Tags" />
                                </Stack>
                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                    <TextField
                                        onChange={InputHandler}
                                        id="category"
                                        fullWidth label="Category" />
                                </Stack>

                                <TextField
                                    fullWidth
                                    id="description"
                                    label="Description"
                                    onChange={InputHandler}

                                // {...getFieldProps('shortDescription')}
                                // error={Boolean(touched.shortDescription && errors.shortDescription)}
                                // helperText={touched.shortDescription && errors.shortDescription}
                                />
                                {renderButton()}
                            </Stack>
                            {/* </Form>
              </FormikProvider> */}
                        </div>
                    </Stack>
                </Card>
            </Container>
        </Page>
    );
}
